import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class ChatBar {
  constructor(block) {
    this.block = block

    //Elements
    this.text = block.querySelectorAll('.chat-bar__heading')
    this.link = block.querySelector('.chat-bar__cta')

    //Animations
    this.animations()
  }

  animations() {
    gsap.from(this.block, {
      scrollTrigger: {
        trigger: this.block,
        start: 'top 100%',
        toggleActions: 'play none none reset'
      },
      filter: 'grayscale(75%)',
      duration: 0.5,
      delay: 0.25
    })

    gsap.fromTo(
      this.block,
      {
        '--background-opacity': '0%'
      },
      {
        scrollTrigger: {
          trigger: this.block,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        '--background-opacity': '100%',
        duration: 0.5,
        delay: 0.25
      }
    )

    if (this.text) {
      this.text.forEach(element => {
        gsap.from(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top 100%',
            toggleActions: 'play none none reset'
          },
          y: '1rem',
          opacity: 0,
          duration: 0.5,
          delay: 0.25
        })
      })
    }

    if (this.link) {
      gsap.from(this.link, {
        scrollTrigger: {
          trigger: this.link,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        right: `-${window.innerWidth - this.link.getBoundingClientRect().left}px`,
        duration: 0.5
      })
    }
  }
}

export default ChatBar
