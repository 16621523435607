import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class OurTeam {
  constructor(block) {
    this.block = block

    //Elements
    this.heading = block.querySelector('.our-team__heading')
    this.members = block.querySelectorAll('.our-team__member')

    //Animations
    this.animations()
  }

  animations() {
    gsap.fromTo(
      this.block,
      {
        '--top-background-opacity': 0
      },
      {
        '--top-background-opacity': 0.25,
        scrollTrigger: {
          trigger: this.block,
          start: 'top 80%',
          toggleActions: 'play none none reverse'
        },
        duration: 0.5,
        delay: 0.25
      }
    )

    gsap.fromTo(
      this.block,
      {
        '--bottom-background-opacity': 0
      },
      {
        '--bottom-background-opacity': 0.5,
        scrollTrigger: {
          trigger: this.block,
          start: '76% bottom',
          toggleActions: 'play none none reverse'
        },
        duration: 0.5,
        delay: 0.25
      }
    )

    if (this.heading) {
      gsap.from(this.heading, {
        scrollTrigger: {
          trigger: this.heading,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        y: '1rem',
        opacity: 0,
        duration: 0.5,
        delay: 0.25
      })
    }

    if (this.members) {
      this.members.forEach(member => {
        const image = member.querySelector('.our-team__member-image')
        const content = member.querySelectorAll('h4, span, p, li')

        if (image) {
          gsap.from(image, {
            scrollTrigger: {
              trigger: member,
              start: 'top 100%',
              toggleActions: 'play none none reset'
            },
            filter: 'blur(50px)',
            scale: 0.875,
            opacity: 0,
            duration: 0.5,
            delay: 0.25
          })
        }

        if (content) {
          gsap.from(content, {
            scrollTrigger: {
              trigger: content,
              start: 'top 100%',
              toggleActions: 'play none none reset'
            },
            y: '1rem',
            opacity: 0,
            duration: 0.5,
            stagger: 0.125
          })
        }
      })
    }
  }
}

export default OurTeam
