import Flickity from 'flickity'
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class Testimonials {
  constructor(block) {
    this.block = block

    //Elements
    this.slider = block.querySelector('.testimonials__slider')
    this.slides = block.querySelectorAll('.testimonials__slide')
    this.cta = block.querySelector('.testimonials__cta')

    //Flickity
    if (this.slider && this.slides.length > 1) {
      this.flickityInit()
    }

    //Animations
    this.animations()
  }

  flickityInit() {
    this.flkty = new Flickity(this.slider, {
      pauseAutoPlayOnHover: false,
      wrapAround: true,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: 5000
    })
  }

  animations() {
    gsap.from(this.block, {
      scrollTrigger: {
        trigger: this.block,
        start: 'top 100%',
        toggleActions: 'play none none reset'
      },
      filter: 'grayscale(75%)',
      duration: 0.5,
      delay: 0.25
    })

    gsap.fromTo(
      this.block,
      {
        '--background-opacity': '0%'
      },
      {
        scrollTrigger: {
          trigger: this.block,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        '--background-opacity': '100%',
        duration: 0.5,
        delay: 0.25
      }
    )

    if (this.text) {
      this.text.forEach(element => {
        gsap.from(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top 100%',
            toggleActions: 'play none none reset'
          },
          y: '1rem',
          opacity: 0,
          duration: 0.5,
          delay: 0.25
        })
      })
    }

    if (this.cta) {
      gsap.from(this.cta, {
        scrollTrigger: {
          trigger: this.cta,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        left: `-${this.cta.getBoundingClientRect().right}px`,
        duration: 0.5
      })
    }
  }
}

export default Testimonials
