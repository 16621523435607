import _ from 'lodash'
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class Services {
  constructor(block) {
    this.block = block

    //Elements
    this.headingElements = block.querySelectorAll('.main-title-row > *')
    this.background = block.querySelector('.services__featured-img')
    this.link = block.querySelector('.services__link')
    this.panelsContainer = block.querySelector('.services__panels')
    this.panels = block.querySelectorAll('.services__panel')

    //Animations
    this.mm = gsap.matchMedia()
    this.animations()
  }

  animations() {
    //Mobile only
    this.mm.add('(max-width: 767px', () => {
      if (this.panelsContainer) {
        const panels = this.panels
        const scrollDist = this.panelsContainer.offsetWidth - window.innerWidth

        gsap.to(this.panelsContainer, {
          scrollTrigger: {
            trigger: this.panelsContainer,
            start: 'bottom bottom',
            end: `+=${scrollDist}px`,
            scrub: true,
            pin: true,
            snap: {
              snapTo: 1 / (panels.length - 1),
              inertia: false,
              duration: {min: 0, max: 0.25}
            }
          },
          x: `-${(100 / panels.length) * (panels.length - 1)}%`,
          ease: 'linear',
          onUpdate() {
            const currentPanel = Math.round(this.progress() * (panels.length - 1))
            panels.forEach(panel => {
              if (panel == panels[currentPanel]) {
                panel.classList.add('services__panel--active')
              } else {
                panel.classList.remove('services__panel--active')
              }
            })
          }
        })

        gsap.to(this.panelsContainer, {
          scrollTrigger: {
            trigger: this.panelsContainer,
            start: 'top 50%',
            onEnter() {
              panels[0].classList.add('services__panel--active')
            },
            onLeaveBack() {
              panels.forEach(panel => {
                panel.classList.remove('services__panel--active')
              })
            }
          }
        })

        gsap.to(this.panelsContainer, {
          scrollTrigger: {
            trigger: this.panelsContainer,
            start: 'bottom 50%',
            onLeaveBack() {
              panels[panels.length - 1].classList.add('services__panel--active')
            },
            onEnter() {
              panels.forEach(panel => {
                panel.classList.remove('services__panel--active')
              })
            }
          }
        })
      }
    })

    if (this.background) {
      gsap.from(this.background, {
        scrollTrigger: {
          trigger: this.background,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        opacity: 0,
        duration: 0.5
      })
    }

    if (this.headingElements) {
      this.headingElements.forEach(element => {
        gsap.from(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top 100%',
            toggleActions: 'play none none reset'
          },
          y: '1rem',
          opacity: 0,
          duration: 0.5,
          delay: 0.25,
          ease: 'power2.out'
        })
      })
    }

    if (this.link) {
      gsap.from(this.link, {
        scrollTrigger: {
          trigger: this.link,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        left: `-${this.link.getBoundingClientRect().right}px`,
        duration: 0.5
      })
    }

    //Non-mobile only
    this.mm.add('(min-width: 768px', () => {
      if (this.panels) {
        gsap.from(this.panels, {
          scrollTrigger: {
            trigger: this.panels,
            start: 'top 100%',
            toggleActions: 'play none none reset'
          },
          opacity: 0,
          y: '50px',
          duration: 1,
          stagger: 0.125,
          ease: 'elastic.out(1, 0.5)'
        })
      }
    })
  }
}

export default Services
