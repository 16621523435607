import 'bootstrap'
import jQuery from 'jquery'
import {gsap} from 'gsap'
gsap.defaults({
  ease: 'power2.out'
})

//Modules
import CursorEffect from './components/CursorEffect'
import Header from './components/Header'
import Hero from './components/Hero'
import SingleTextColumn from './components/SingleTextColumn'
import ClientsCarousel from './components/ClientsCarousel'
import Services from './components/Services'
import FeaturedWork from './components/FeaturedWork'
import Testimonials from './components/Testimonials'
import ChatBar from './components/ChatBar'
import InnerBanner from './components/InnerBanner'
import OurWork from './components/OurWork'
import ApproachDetails from './components/ApproachDetails'
import OurTeam from './components/OurTeam'
import ServicesDetails from './components/ServicesDetails'

document.querySelectorAll('header').forEach(header => {
  new Header(header)
})

document.querySelectorAll('.hero-wrapper').forEach(block => {
  new Hero(block)
})

document.querySelectorAll('.single-text-column').forEach(block => {
  new SingleTextColumn(block)
})

document.querySelectorAll('.clients-carousel').forEach(block => {
  new ClientsCarousel(block)
})

document.querySelectorAll('.services').forEach(block => {
  new Services(block)
})

document.querySelectorAll('.featured-work').forEach(block => {
  new FeaturedWork(block)
})

document.querySelectorAll('.testimonials').forEach(block => {
  new Testimonials(block)
})

document.querySelectorAll('.chat-bar').forEach(block => {
  new ChatBar(block)
})

document.querySelectorAll('.inner-banner-wrapper').forEach(block => {
  new InnerBanner(block)
})

document.querySelectorAll('.our-work').forEach(block => {
  new OurWork(block)
})

document.querySelectorAll('.approach-details').forEach(block => {
  new ApproachDetails(block)
})

document.querySelectorAll('.our-team').forEach(block => {
  new OurTeam(block)
})

document.querySelectorAll('.services-details').forEach(block => {
  new ServicesDetails(block)
})

new CursorEffect()
