import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class Hero {
  constructor(block) {
    this.block = block

    //Elements
    this.leftTitle = block.querySelector('.hero-wrapper__title--left')
    this.rightTitle = block.querySelector('.hero-wrapper__title--right')
    this.link = block.querySelector('.hero-wrapper__hero-btn')

    //Animations
    this.animations()
  }

  animations() {
    if (this.leftTitle) {
      gsap.from(this.leftTitle, {
        scrollTrigger: {
          trigger: this.block,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        x: '-100%',
        opacity: 0,
        duration: 0.5
      })
    }

    if (this.rightTitle) {
      gsap.from(this.rightTitle, {
        scrollTrigger: {
          trigger: this.block,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        x: '100%',
        opacity: 0,
        duration: 0.5,
        delay: 0.25
      })
    }

    if (this.link) {
      gsap.from(this.link, {
        scrollTrigger: {
          trigger: this.block,
          start: 'top 100%',
          toggleActions: 'play none none reset'
        },
        left: `-${this.link.getBoundingClientRect().right}px`,
        duration: 0.5,
        delay: 1
      })
    }
  }
}

export default Hero
